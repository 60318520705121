import React from 'react'
import { StaticImage } from "gatsby-plugin-image"
import Layout from '../../../components/Layout'
import styled from 'styled-components';

import "./style.css"


const ImageWraper = styled.div`

  text-align: center;  
  margin-bottom: 20px;

  height: auto;
  width: auto;  

  @media screen and (max-width: 960px) {
    width: auto;  
  }

`



const Information = () => {

  return (
      <div className="boda-box">
          <br/>
          <h1>Buenos Aires</h1>
            <ImageWraper>
              <StaticImage className="container" src="../../../images/wedding/bsas.jpg"  width={1200}  height={1200} />                                  
            </ImageWraper>   
            <div>
              <h1>SCHEDULE</h1>
              <br/>
              <h2>WEDDING</h2>
              <br/> 
              <p>Friday 6th of January 2023</p>
              <br/>
              <p>17:00h</p>              
              <h3>Arrival at Estancia Santa Elena</h3>                     
            </div>
            <br/>
            <br/> 
            <h2>Address</h2>
            <br/>
            <p>Estancia Santa Elena</p>              
            <p><a href="https://goo.gl/maps/dwByhvnU7tePXLQ27" target="_blank" rel="noreferrer" >Dr. Muñiz 720<br/> Jáuregui, Provincia de Buenos Aires<br/> Argentina</a></p>  
            <br/>
            <h2>Transport from Buenos Aires</h2>
            <br/>
            <p>If you need a transport to and from Santa Elena please let us know or check the travel section.</p>
            <br/>
            <h2>WHERE TO STAY?</h2>
            <br/>
            <p>We have a special section for acomodation near Santa Elena click <a href="/travel/santaelena/">here</a> to see it or check the travel section.</p>
            <br/>
            <br/>            
        </div>
    )
}



const Page = () => {

  return (
    <Layout section="wedding" pageTitle="Wedding">
      <Information />
    </Layout>
  )
}

export default Page